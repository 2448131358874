window.addEventListener('turbolinks:load', () => {
  function closeModal() {
    document.querySelector("#copy-modal").style.visibility = 'hidden'
  }

  const modal = document.querySelector("#copy-modal")
  if (modal !== null) {
    modal.style.visibility = 'hidden'
  }

  const button = document.querySelector('#copy-button')
  if (button === null) return

  button.addEventListener('click', () => {
    const value = button.dataset.value
    navigator.clipboard.writeText(value).then(() => {
      modal.style.visibility = 'visible'
    })
  })

  const closeButtons = document.querySelectorAll('#copy-modal-close-button')
  const submitButton = document.getElementById('copy-modal-submit')
  closeButtons.forEach((button) => {
    button.addEventListener('click', () => { closeModal() })
  })
  submitButton && submitButton.addEventListener('click', () => {
    window.open(submitButton.dataset.url, '_blank')
    closeModal()
  })
})