import { csrfToken } from "@rails/ujs";
import Cookies from 'js-cookie';

window.addEventListener('turbolinks:load', () => {
  const googletag = window.googletag || { cmd: [] };

  var isDisplayReward = false
  var rewardEvent = null

  const setup = () => {
    // display of modal about new reward notify
    if (parseInt(Cookies.get('new-reward-add')) === 1) {
      Cookies.remove('new-reward-add')
      document.querySelector('#grantModal').style.visibility = 'visible'
    }

    // modal closeButton
    const closeButton = document.querySelector('#grantCloseBtn')
    if (closeButton !== null) {
      closeButton.addEventListener('click', () => {
        document.querySelector('#grantModal').style.visibility = 'hidden'
      })
    }
  }

  const ready = (event) => {
      if (isDisplayReward) {
        event.makeRewardedVisible()
        const loading = document.querySelector("#loading")
        loading.style.visibility = 'hidden'
        return
      }

      // prepare async
      rewardEvent = event
  }

  const granted = (event) => {
    const response = fetch("/themes/reward", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken(),
      },
    });

    response.then((res) => {
      Cookies.set('new-reward-add', '1')
      location.reload()
    })
  }

  const closed = (event) => {
    console.log("reward closed")
    location.reload()
  }

  setup()

  // reward start
  const button = document.querySelector('#watchAdBtn')
  if (button === null) {
    return
  }

  button.addEventListener('click', (event) => {
    isDisplayReward = true

    if (rewardEvent !== null) {
      rewardEvent.makeRewardedVisible()
      return
    }

    const loading = document.querySelector("#loading")
    loading.style.visibility = 'visible'
  })

  googletag.cmd.push(() => {
    const rewardedSlot = googletag.defineOutOfPageSlot(button.dataset.id, googletag.enums.OutOfPageFormat.REWARDED).addService(googletag.pubads());
    rewardedSlot.setForceSafeFrame(true);
    googletag.pubads().enableAsyncRendering();
    googletag.enableServices();
    googletag.pubads().addEventListener('rewardedSlotReady', ready)
    googletag.pubads().addEventListener('rewardedSlotGranted', granted)
    googletag.pubads().addEventListener('rewardedSlotClosed', closed);
    googletag.display(rewardedSlot);
  })
})