// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
import LazyLoad from "vanilla-lazyload"

import "channels"

import "./scss/application.scss";
import "spinkit/spinkit.min.css";

require("./copy-data")
require("./flash-box")
require("./theme-title-form")
require("./cache_input")
require("./redirect")
require("./modal")
require("./copy-url")
require("./reward")

window.addEventListener('turbolinks:load', () => {
  var lazyLoad = new LazyLoad()
})
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
