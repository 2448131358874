import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from 'react-image-crop'
import styles from "../styles/mypage/ProfileImageForm.module.scss";
import "react-image-crop/dist/ReactCrop.css";
import { csrfToken } from "@rails/ujs";

export const ProfileImageForm = ({ uploadImage, setProfileImage, closeForm }) => {
  const [crop, setCrop] = useState({
    unit: "px",
    width: 300,
    x: 20,
    y: 20,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [image, setImage] = useState("");
  const imgRef = useRef(null);

  useEffect(() => {
    if (uploadImage) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImage(reader.result));
      reader.readAsDataURL(uploadImage);
    }
  }, []);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const getCroppedImage = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          blob.name = "profile_image.jpeg";
          blob.lastModifiedDate = new Date();
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  };

  const getS3PresignedUrl = async () => {
    const response = await fetch("/s3_direct_post", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken(),
      },
    });
    const directPost = await response.json();
    return directPost;
  };

  const uploadS3 = async (image) => {
    const s3DirectPost = await getS3PresignedUrl();
    const fields = s3DirectPost["fields"];
    const formData = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });
    formData.append(
      "file",
      new File([image], uploadImage.name, { type: "application/octet-stream" })
    );

    const ret = await fetch(s3DirectPost["url"], {
      method: "POST",
      headers: { Accept: "multipart/form-data" },
      body: formData,
    });
    const resText = await ret.text();
    const resXML = parseXML(resText);
    const key = resXML.getElementsByTagName("Key")[0].childNodes[0].nodeValue;
    updateProfileImage(key);
  };

  const parseXML = (text) => {
    return new DOMParser().parseFromString(text, "application/xml");
  };

  const updateProfileImage = async (key) => {
    const response = await fetch("/mypage/profile_image", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken(),
      },
      body: JSON.stringify({ user: { key: key } }),
    });
    if (response.ok) {
      response.json().then((data) => {
        setProfileImage(data["fileUrl"]);
        closeForm();
      });
    } else {
      response.json().then((data) => {
        alert(
          `画像の変更に失敗しました。やり直してください。\n${data["error"]}`
        );
      });
    }
  };

  return (
    <div className={styles.form}>
      <div className={styles.buttonContainer}>
        <button
          type="button"
          onClick={() => closeForm()}
          className={styles['button--back']}
        >
          戻る
        </button>
        <button
          type="button"
          disabled={!completedCrop?.width || !completedCrop?.height}
          onClick={() => {
            getCroppedImage(imgRef.current, crop).then((image) => {
              uploadS3(image);
            });
          }}
          className={styles.button}
        >
          完了
        </button>
      </div>
      <ReactCrop
        src={image}
        crop={crop}
        onChange={(newCrop) => setCrop(newCrop)}
        onComplete={(crop) => setCompletedCrop(crop)}
        onImageLoaded={onLoad}
        circularCrop={true}
      />
    </div>
  );
};
