window.addEventListener('turbolinks:load', () => {
  const titleInput = document.querySelector(".js-text-counter-input")
  if (!titleInput) return

  const counter = document.querySelector(".js-text-counter")

  if (counter) {
    counter.innerHTML = titleInput.value.length
    titleInput.addEventListener("input", (event) => {
      counter.innerHTML = event.target.value.length
    })
  }
})
