import consumer from "./consumer"

window.addEventListener('turbolinks:load', () => {
  const element = document.querySelector(".js-channel")
  if (element === null) {
    return
  }

  consumer.subscriptions.create({ channel: "ThemesChannel", device_uid: element.dataset.deviceUid }, {
    received(_) {
      location.reload()
    }
  })
})