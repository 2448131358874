window.addEventListener('turbolinks:load', () => {
  function copy(value, url) {
    navigator.clipboard.writeText(value).then(() => {
      window.open(url)
    })
  }

  var textarea = document.querySelector("#copy_data")
  let buttons = document.querySelectorAll(".js-app-download")
  buttons.forEach(button => {
    button.addEventListener("click", () => {
      if(textarea) {
        copy(textarea.value, button.dataset.url)
      } else {
        window.open(button.dataset.url)
      }
    })
  })
})