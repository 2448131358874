window.addEventListener('turbolinks:load', () => {
  function closeModal() {
    document.getElementById('ninjar-modal').style.visibility = 'hidden'
  }

  function openModal() {
    document.getElementById('ninjar-modal').style.visibility = 'visible'
  }

  const closeButtons = document.querySelectorAll('#ninjar-modal-close-button')
  const openButton = document.getElementById('ninjar-modal-open-button')
  const submitButton = document.getElementById('ninjar-modal-submit')
  closeButtons.forEach((button) => {
    button.addEventListener('click', () => { closeModal() })
  })
  openButton && openButton.addEventListener('click', () => { openModal() })
  submitButton && submitButton.addEventListener('click', () => {
    window.open(submitButton.dataset.url, '_blank')
    closeModal()
  })
})
