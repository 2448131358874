import React, { useState, useRef } from 'react'
import { ProfileImageForm } from './ProfileImageForm'

export const ProfileImageIcon = ({ image, className, imageWidth='90' }) => {
  const [displayProfileImageForm, setDisplayProfileImageForm] = useState(false)
  const [uploadImage, setuploadImage] = useState(null)
  const [profileImage, setProfileImage] = useState(image)
  const inputRef = useRef(null);

  const closeForm = () => {
    setDisplayProfileImageForm(false)
    setuploadImage(null);
  }

  return (
    <>
      <img
        src={profileImage}
        className={className}
        width={imageWidth}
        onClick={() => inputRef.current.click()}
      />
      <input
        hidden
        accept="image/*"
        type="file"
        onChange={(event) => {
          setuploadImage(event.target.files[0]);
          setDisplayProfileImageForm(true);
        }}
        ref={inputRef}
      />
      {displayProfileImageForm && uploadImage && (
        <ProfileImageForm
          uploadImage={uploadImage}
          setProfileImage={setProfileImage}
          closeForm={() => closeForm()}
        />
      )}
    </>
  );
}

export default ProfileImageIcon
