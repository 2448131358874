import Cookies from 'js-cookie'

window.addEventListener('turbolinks:load', () => {
  var textarea = document.querySelector(".js-cache-input")
  var submit = document.querySelector(".js-cache-submit")

  if (submit == null || textarea == null) {
    return
  }

  submit.addEventListener('click', () => {
    Cookies.set(textarea.dataset.cacheName, textarea.value)
  })
})