import React, { useState, useRef } from 'react'
import { ProfileImageForm } from "./ProfileImageForm";

const ProfilesIndex = ({ image, className, imageWidth = "90" }) => {
  const [displayProfileImageForm, setDisplayProfileImageForm] = useState(false);
  const [uploadImage, setuploadImage] = useState(null);
  const [profileImage, setProfileImage] = useState(image);
  const inputRef = useRef(null);

  const closeForm = () => {
    setDisplayProfileImageForm(false);
    setuploadImage(null);
  };

  return (
    <>
      <div
        className="ninjar-mypage-menu-link"
        id="ninjar-modal-open-button"
        onClick={() => inputRef.current.click()}
      >
        <div className="ninjar-mypage-menu-link-content">
          <div className="ninjar-mypage-menu-link-content-container">
            <div className="ninjar-mypage-menu-link-content__text">写真</div>
          </div>
          <img src={profileImage} className={className} width={imageWidth} />
        </div>
        <div className="ninjar-mypage-menu-link__arrow">＞</div>
      </div>
      <input
        hidden
        accept="image/*"
        type="file"
        onChange={(event) => {
          setuploadImage(event.target.files[0]);
          setDisplayProfileImageForm(true);
        }}
        ref={inputRef}
      />
      {displayProfileImageForm && uploadImage && (
        <ProfileImageForm
          uploadImage={uploadImage}
          setProfileImage={setProfileImage}
          closeForm={() => closeForm()}
        />
      )}
    </>
  );
};

export default ProfilesIndex
